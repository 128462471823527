/*
 * JS
 */
; (() => {
  // Imports
  const _Enum = require('enum') // Npm: utility module.
  const _CountryList = require('country-list') // Npm: Country list.
  const _CurrencyList = require('currency-codes') // Npm: Currency list.

  /*
   * CLASS
   */
  class ConcurrentTask {
    // Constructor.
    constructor(taskPromisesFunc = [], batchSize = 1) {
      // Const assignment.
      this.batchSize = batchSize > taskPromisesFunc.length ? taskPromisesFunc.length : batchSize
      this.todoTasks = taskPromisesFunc
      this.resolvedValues = []
    }

    /*
     * Method to execute resolve and reject
     * methods of given promise.
     */
    run(resolve, reject) {
      // Loop over methods size to execute on.
      if (0 < this.todoTasks.length) {
        /*
         * Splice given promise into chunk say.
         * if there is batch size of 2 then two.
         * function will execute at once.
         */
        const taskPromises = this.todoTasks.splice(0, this.batchSize)

        // Run promise for given method at given position.
        Promise.all(taskPromises.map(p => p()))
          .then(resolvedValues => {
            // Update resolved values.
            this.resolvedValues = [...this.resolvedValues, ...resolvedValues]

            // Run resolve or reject condition.
            this.run(resolve, reject)
          }).catch(error => reject(error))
      } else {
        // Resolve give values.
        resolve(this.resolvedValues)
      }
    }

    // Return single promise instead of multiple.
    runTasks() {
      // Return promise.
      return new Promise((resolve, reject) => {
        this.run(resolve, reject)
      })
    }
  }

  // Array prototype extends
  Array.indexestr = (__arr, __val) => {
    // Local variable.
    let _i

    // Const assignment.
    const _indexes = []

    // Variable assignment.
    _i = -1

    /*
     * Consider doing only if __arr is arrays
     * nothing else.
     */
    if (__arr.constructor !== Array) return __arr

    // While indexes count
    while (-1 !== (_i = __arr.indexOf(__val, _i + 1))) {
      // Push index
      _indexes.push(_i)
    }

    // Return indexes
    return _indexes
  }

  // Object prototype extends
  Object.dot = __object => ({
    'get': (__path, __defaultValue) => __path.split('.').reduce((o, p) => (o ? o[p] : __defaultValue), __object),
    // eslint-disable-next-line no-cond-assign
    'set': (__path, __value) => __path.split('.').reduce((o, p, i) => (o[p] = (__path.split('.').length += i) ? __value : o[p] || {}), __object)
  })
  Object.depthOf = __object => {
    // Local variable.
    let _jamaica, _level

    // Variable assignment.
    _level = 1

    // Loop over object properties.
    for (_jamaica in __object) {
      // HasOwnProperty check.
      if (!__object.hasOwnProperty(_jamaica)) continue

      /*
       * Only continue if given object value
       * is typeof object.
       */
      if ('object' === typeof __object[_jamaica]) {
        // Const assignment.
        const depthBy = 1
        const _depth = Object.depthOf(__object[_jamaica]) + depthBy

        // Variable assignment.
        _level = Math.max(_depth, _level)
      }
    }

    // Return depth.
    return _level
  }
  Object.filterOut = (__obj, __predicate) => {
    // Local variable.
    let _key

    // Const assignment.
    const result = {}

    // Loop over object keys.
    for (_key in __obj) {
      // Conditional checking for objects.
      if (__obj.hasOwnProperty(_key) && __predicate(__obj[_key])) {
        // Push object value to new set.
        result[_key] = __obj[_key]
      }
    }

    // Return result
    return result
  }
  Object.DateRangeProvider = () => {
    // Const assignment.
    const _endDate = new Date()

    // Subtract one day from yesterday.
    _endDate.setDate(_endDate.getDate() + 1)

    // Set time to 00:00:00
    _endDate.setHours(0, 0, 0, 0)

    // Set start date to yesterday.
    const _startDate = new Date(_endDate)

    // Update time to 00:00:00
    _startDate.setHours(0, 0, 0, 0)

    // Set start date to 30 days ago.
    _startDate.setDate(_startDate.getDate() - 1)

    // Return date range.
    return ({
      'endDate': _endDate.toISOString(),
      'startDate': _startDate.toISOString()
    })
  }
  Object.DateRangeProviderMonth = () => {
    // Get today's date.
    const _endDate = new Date()
    // Set the start date to the first day of the current month.
    const _startDate = new Date(_endDate.getFullYear(), _endDate.getMonth(), 1)
    // Adjust _startDate to midnight to avoid timezone issues.
    _startDate.setHours(0, 0, 0, 0)

    // Return the date range in ISO format.
    return {
      'startDate': _startDate.toISOString(),
      'endDate': _endDate.toISOString()
    }
  }

  // String prototype extends
  String.toCapitalize = function toCapitalize(__string) {
    // Variable assignment.
    __string = __string ? __string.toLowerCase() : __string

    /*
     * If given string is not empty than use it
     * else prefer this.
     */
    if (__string && 'string' === typeof __string) return __string.charAt(0).toUpperCase() + __string.slice(1)

    // Return capitalize.
    return __string ? this.charAt(0).toUpperCase() : void 0
  }
  String.isValidRegex = __regex => {
    // Try catch block.
    try {
      // Variable assignment.
      // eslint-disable-next-line no-new,require-unicode-regexp
      new RegExp(__regex)

      // Return true.
      return true
    } catch (_error) {
      // Return false.
      return false
    }
  }
  String.toCamelCase = __string =>
    // Return replaced string.
    __string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/gu, (__match, __index) => {
      // Return string on null match
      if (0 === Number(__match)) return ''

      // Return camel case.
      return 0 === __index ? __match.toLowerCase() : __match.toUpperCase()
    })
  String.random = length => {
    // Local variable.
    let _result, i

    // Variable assignment.
    _result = ''

    // Const assignment.
    const _characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const _charactersLength = _characters.length

    // Loop over length and generate string.
    for (i = 0; i < length; i++) {
      // Concat random char at given random index.
      _result += _characters.charAt(Math.floor(Math.random() * _charactersLength))
    }

    // Return result.
    return _result
  }
  String.toChopByGivenLength = (__String, __length) => __String?.length <= __length ? __String : `${__String?.slice?.(0, __length)}...`

  // React.js object extension with useful common functions.
  Object.React = {
    'App': {
      'fetchPolicy': 'network-only',
      'pollInterval': 7000,
      'enums': {
        'ACCOUNT_TYPE': new _Enum([
          'WHOLESALE',
          'RESELLER',
          'RETAIL',
          'TERMINATION',
          'EMPLOYEE'
        ]),
        'CUSTOMER_TYPE': new _Enum(['WHOLESALE', 'RESELLER', 'RETAIL', 'TERMINATION']),
        'VENDOR_TYPE': new _Enum(['TERMINATION']),
        'BILLING_PERIOD': new _Enum([
          'SIXTY_DAYS',
          'FIFTEEN_DAYS',
          'SEVEN_DAYS',
          'MONTHLY',
          'TWICE_A_MONTH'
        ]),
        'GRAPHQL_SUCCESSFUL_QUERY_FLAGS': new _Enum([
          'UPSERT_SUCCESSFUL',
          'DELETE_SUCCESSFUL',
          'READ_SUCCESSFUL',
          'UPDATE_SUCCESSFUL',
          'CREATE_SUCCESSFUL'
        ]),
        'CONTENT_TRANSLATION_STRATEGY': new _Enum(['REPLACE', 'EXTRACT']),
        'CONTENT_TRANSLATION_TYPE': new _Enum(['WHOLESALE', 'RESELLER', 'RETAIL', 'TERMINATION']),
        'SMS_TYPE': new _Enum(['OTP', 'PROMOTIONAL', 'TRANSACTIONAL']),
        'SMS_STATUS': new _Enum([
          'NONE',
          'ENROUTE',
          'DELIVERED',
          'EXPIRED',
          'DELETED',
          'UNDELIVERABLE',
          'ACCEPTED',
          'UNKNOWN',
          'REJECTED',
          'FAILED',
          'SUBMITTED'
        ]),
        'SMS_BUILT_TYPE': new _Enum(['TEXT', 'UNICODE']),
        'TRUNK_TYPE': new _Enum([
          'DIRECT',
          'HQ',
          'SIM',
          'SPAM',
          'WHOLESALE',
          'FULLY_FEATURE',
          'SS7'
        ]),
        'SMPP_DEBUGGING_LEVEL': new _Enum([
          'NONE',
          'ERROR',
          'INFO',
          'BIND',
          'UNBIND',
          'CLOSE'
        ]),
        'PLATFORM_SERVICE_TYPE': new _Enum(['SMPP']),
        'COUNTRY_LIST': new _Enum(Object.entries(_CountryList.getCodeList())?.map(i => i?.[1]?.toUpperCase())),
        'CURRENCY_LIST': new _Enum([..._CurrencyList.codes(), 'SMS'])
      },
      'smppError': {
        '0': 'ESME_ROK - No Error',
        '1': 'ESME_RINVMSGLEN - Message Length is invalid',
        '2': 'ESME_RINVCMDLEN - Command Length is invalid',
        '3': 'ESME_RINVCMDID - Invalid Command ID',
        '4': 'ESME_RINVBNDSTS - Incorrect BIND Status for given command',
        '5': 'ESME_RALYBND - ESME Already in Bound State',
        '6': 'ESME_RINVPRTFLG - Invalid Priority Flag',
        '7': 'ESME_RINVREGDLVFLG - Invalid Registered Delivery Flag',
        '8': 'ESME_RSYSERR - System Error',
        '10': 'ESME_RINVSRCADR - Invalid Source Address',
        '11': 'ESME_RINVDSTADR - Invalid Destination Address',
        '12': 'ESME_RINVMSGID - Message ID is invalid',
        '13': 'ESME_RBINDFAIL - Bind Failed',
        '14': 'ESME_RINVPASWD - Invalid Password',
        '15': 'ESME_RINVSYSID - Invalid System ID',
        '17': 'ESME_RCANCELFAIL - Cancel SM Failed',
        '19': 'ESME_RREPLACEFAIL - Replace SM Failed',
        '20': 'ESME_RMSGQFUL - Message Queue Full',
        '21': 'ESME_RINVSERTYP - Invalid Service Type',
        '33': 'ESME_RINVNUMDESTS - Invalid number of destinations',
        '34': 'ESME_RINVDLNAME - Invalid Distribution List name',
        '40': 'ESME_RINVDESTFLAG - Destination flag is invalid',
        '42': 'ESME_RINVSUBREP - Invalid ‘submit with replace’ request',
        '43': 'ESME_RINVESMCLASS - Invalid esm_class field data',
        '44': 'ESME_RCNTSUBDL - Cannot Submit to Distribution List',
        '45': 'ESME_RSUBMITFAIL - Submit SM Failed',
        '48': 'ESME_RINVSRCTON - Invalid Source address TON',
        '49': 'ESME_RINVSRCNPI - Invalid Source address NPI',
        '50': 'ESME_RINVDSTTON - Invalid Destination address TON',
        '51': 'ESME_RINVDSTNPI - Invalid Destination address NPI',
        '53': 'ESME_RINVSYSTYP - Invalid system_type field',
        '54': 'ESME_RINVREPFLAG - Invalid replace_if_present flag',
        '55': 'ESME_RINVNUMMSGS - Invalid number of messages',
        '64': 'ESME_RTHROTTLED - Throttling error (ESME has exceeded allowed message limits)',
        '66': 'ESME_RINVSCHED - Invalid Scheduled Delivery Time',
        '67': 'ESME_RINVEXPIRY - Invalid message validity period (Expiry time)',
        '68': 'ESME_RINVDFTMSGID - Predefined Message ID is Invalid',
        '69': 'ESME_RX_T_APPN - ESME Receiver Temporary App Error Code',
        '70': 'ESME_RX_P_APPN - ESME Receiver Permanent App Error Code',
        '71': 'ESME_RX_R_APPN - ESME Receiver Reject Message Error Code',
        '72': 'ESME_RQUERYFAIL - Query SM Failed',
        '88': 'ESME_RINVOPTPARSTREAM - Error in the optional part of the PDU Body',
        '89': 'ESME_ROPTPARNOTALLWD - Optional Parameter not allowed',
        '90': 'ESME_RINVPARLEN - Invalid Parameter Length',
        '91': 'ESME_RMISSINGOPTPARAM - Expected Optional Parameter missing',
        '92': 'ESME_RINVOPTPARAMVAL - Invalid Optional Parameter Value',
        '200': 'ESME_RDELIVERYFAILURE - Delivery Failure (used for data_sm_resp)',
        '201': 'ESME_RUNKNOWNERR - Unknown Error'
      }
    },
    'SmppTimeFormatter': __timestamp => {
      // Return if timestamp is empty.
      if (!__timestamp) return ''

      // Const assignment.
      const _time = __timestamp.slice(-4)
      const _date = __timestamp.slice(0, -4)
      const _currentYear = new Date().getFullYear().toString()

      // Date Instance.
      const _Date = new Date()

      /*
       * Update all time and date
       * properties on _Date instance.
       */
      _Date.setFullYear(_currentYear)
      _Date.setMonth(parseInt(_date.slice(2, 4), 10) - 1)
      _Date.setDate(parseInt(_date.slice(4, 6), 10))
      _Date.setHours(parseInt(_time.slice(0, 2), 10) + 4)
      _Date.setMinutes(parseInt(_time.slice(2, 4), 10) + 30)
      _Date.setSeconds(0)
      _Date.setMilliseconds(0)

      // Return formatted date and time
      return _Date
    },
    'CreateColorBasedOnAlphabet': inputString => {
      // Return default color if nothing is passed.
      if (!inputString || 0 === inputString.length) {
        return { 'bgColor': '#000000', 'fontColor': '#FFFFFF' }
      }

      // Const assignment.
      const char = inputString[0].toUpperCase()
      const asciiValue = char.charCodeAt(0)

      // Rgb percentage values for shades of blue, purple, pink, and black.
      const red = (asciiValue * 80) % 200 // Red can vary up to 200
      const green = (asciiValue * 30) % 80 // Green kept lower to avoid moving into other color spectrums
      const blue = 150 + (asciiValue * 50) % 105 // Blue is always high, varying between 150 and 255

      // Rgb hex values.
      const redHex = red.toString(16).padStart(2, '0')
      const greenHex = green.toString(16).padStart(2, '0')
      const blueHex = blue.toString(16).padStart(2, '0')

      // Function to calculate luminance.
      const calculateLuminance = (r, g, b) => {
        const a = [r, g, b].map(v => {
          v /= 255

          return 0.03928 >= v ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4
        })

        return (a[0] * 0.2126) + (a[1] * 0.7152) + (a[2] * 0.0722)
      }

      // Calculate luminance based on actual RGB values, not hex.
      const luminance = calculateLuminance(red, green, blue)

      // Return bg and font color.
      return {
        'bgColor': `#${redHex}${greenHex}${blueHex}`,
        'fontColor': 0.179 < luminance ? '#000000' : '#FFFFFF'
      }
    },
    'JoiErrorHandler': (__errors, __name, __cb) => {
      // Loop over error and fix them one by one.
      __errors.forEach(__error => {
        switch (__error.type) {
          case 'any.required':
            // Report failure.
            __error.message = `${__name.toUpperCase()}_INPUT_EMPTY`

            // Run given callback.
            __cb?.()

            // Break statement.
            break
          default:
            // Report failure.
            __error.message = `${__name.toUpperCase()}_INVALID_INPUT`

            // Run given callback.
            __cb?.()

            // Break statement.
            break
        }
      })

      // Return error.
      return __errors
    },
    'CopyToClipBoard': text => {
      // Copy text content to clipboard.
      if ('clipboard' in navigator) return navigator.clipboard.writeText(text)

      // Execute copy.
      return document.execCommand('copy', true, text)
    },
    'GenerateOtp': () => {
      // Variable declaration.
      let _otp, i

      // Variable assignment.
      _otp = ''

      /*
       * Loop over 4 digits to generate
       * otp.
       */
      for (i = 0; 4 > i; i++) _otp += '0123456789'[Math.floor(Math.random() * 10)]

      // Return generated otp.
      return _otp
    },
    'URLRemoveParams': (url, parameter) => {
      // Better to use l.search if you have a location/link object
      const urlparts = url.split('?')
      if (2 <= urlparts.length) {
        const prefix = `${encodeURIComponent(parameter)}=`

        const pars = urlparts[1].split(/[&;]/ug)

        // Reverse iteration as may be destructive
        for (let i = pars.length; 0 < i--;) {
          // Idiom for string.startsWith
          if (-1 !== pars[i].lastIndexOf(prefix, 0)) {
            pars.splice(i, 1)
          }
        }

        url = urlparts[0] + (0 < pars.length ? `?${pars.join('&')}` : '')

        return url
      }

      return url
    },
    'FormatBytes': (__bytes, __decimals = 2) => {
      // Return default value if not a number.
      if (!Number(__bytes)) return '0 Bytes'

      // Const assignment.
      const _base = 1024
      const _sizes = [
        'Bytes',
        'KiB',
        'MiB',
        'GiB',
        'TiB',
        'PiB',
        'EiB',
        'ZiB',
        'YiB'
      ]
      const _bytes = Math.floor(Math.log(__bytes) / Math.log(_base))

      // Return formatted bytes.
      return `${parseFloat((__bytes / (_base ** _bytes)).toFixed(0 > __decimals ? 0 : __decimals))} ${_sizes[_bytes]}`
    },
    'ConcurrentTask': ConcurrentTask,
    'useSSE': (__subscription, __params, __target) => {
      // Object assignment.
      const React = require('react')

      // Const assignment.
      const _subscriptionURL = new URL(process.env.REACT_APP_NODE_BACKEND)

      // Hook assignment.
      const [data, setData] = React.useState(void 0)

      // Variable assignment.
      _subscriptionURL.searchParams.append('query', __subscription)
      _subscriptionURL.searchParams.append('variables', JSON.stringify(__params))

      // Event listener.
      React.useEffect(() => {
        // Const assignment.
        const _EventSource = new EventSource(_subscriptionURL.toString())

        // Event listener.
        _EventSource.onmessage = event => {
          // Parse the event data
          const _eventData = JSON.parse(event.data)

          // Check if the received event matches the subscription definition
          if (_eventData.data?.[__target]) setData(_eventData.data?.[__target])
        }

        // Return cleanup function.
        return () => {
          // Clean up the EventSource connection when the component unmounts
          _EventSource.close()
        }
      }, [__subscription])

      // Return data.
      return data
    }
  }
})()
