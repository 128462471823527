/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: React.js library.
import Debounce from 'lodash/debounce' // Npm: lodash 
import _ from 'underscore' // Npm: Utility module.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiPlay, HiStop } from 'react-icons/hi2' // Npm: React icons.
import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useStyleConfig
} from '@chakra-ui/react' // Npm: A simple, modular and accessible component library for React.js.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'


/*
 * GRAPHS
 */
import VendorAccountConnectedQuery from './__query__/index.vendorAccount.connected.query'
import SmppActiveInActiveMutation from './__mutation__/index.smpp.activeInActive.mutation'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * OBJECTS
 */
function Index({ title, isConnect }) {
  // Hook assignment.
  const [searchQuery, setSearchQuery] = React.useState('')
  const [playIsLoading, setPlayIsLoading] = React.useState([])
  const [isCacheClearLoading, setIsCacheClearLoading] = React.useState([])
  const [MutationSmppActiveInActive] = useMutation(SmppActiveInActiveMutation)
  const _QueryVendorAccountConnected = useQuery(VendorAccountConnectedQuery, { 'variables': { 'isActive': Boolean(isConnect) }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])

  const _styles = useStyleConfig('Card')
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  const filteredData = (_QueryVendorAccountConnected?.data?.VendorAccountConnected || []).filter(
    ({ displayName, status }) =>
      displayName?.toLowerCase().includes(searchQuery?.toLowerCase() || '') &&
      status === 'READ_SUCCESSFUL' // Ensure status is valid
  );
  // Component assignment..
  const TableToRender = () => (
    <TableContainer
      w='100%'
      border='1px solid rgba(216, 227, 252, 1)'
      borderRadius='15px'
      minH='400px'
      maxH='400px'
      overflowY='scroll'>
      <Table colorScheme='brand' size='sm' width='100%'>
        <Thead bg='rgba(244, 247, 254, 1)'>
          <Tr>
            <Th
              height='40px'
              color='rgba(141, 151, 182, 1)'
              fontWeight='300px'
              borderRight='1px solid rgba(216, 227, 252, 1)'
              fontFamily='DM Sans'>
              <Flex justify='center' align='center'>
                S.No.
              </Flex>{' '}
            </Th>
            <Th
              w='100px'
              height='40px'
              color='rgba(141, 151, 182, 1)'
              fontWeight='300px'
              borderRight='1px solid rgba(216, 227, 252, 1)'
              fontFamily='DM Sans'>
              <Flex justify='center' align='center'>
                Action
              </Flex>{' '}
            </Th>
            <Th
              height='40px'
              color='rgba(141, 151, 182, 1)'
              fontWeight='300px'
              borderRight='1px solid rgba(216, 227, 252, 1)'
              fontFamily='DM Sans'>
              <Flex justify='center' align='center'>
                Account
              </Flex>{' '}
            </Th>
            <Th
              height='40px'
              color='rgba(141, 151, 182, 1)'
              fontWeight='300px'
              borderRight='1px solid rgba(216, 227, 252, 1)'
              fontFamily='DM Sans'>
              <Flex justify='center' align='center'>
                Status
              </Flex>{' '}
            </Th>
            <Th
              height='40px'
              color='rgba(141, 151, 182, 1)'
              fontWeight='300px'
              fontFamily='DM Sans'>
              <Flex justify='center' align='center'>
                Mode
              </Flex>{' '}
            </Th>
          </Tr>
          <Tr>
            <Th
              height='40px'
              color='rgba(141, 151, 182, 1)'
              fontWeight='300px'
              borderRight='1px solid rgba(216, 227, 252, 1)'
              fontFamily='DM Sans'>
            </Th>
            <Th
              height='40px'
              color='rgba(141, 151, 182, 1)'
              fontWeight='300px'
              borderRight='1px solid rgba(216, 227, 252, 1)'
              fontFamily='DM Sans'>
            </Th>
            <Th
              height='40px'
              color='rgba(141, 151, 182, 1)'
              borderRight='1px solid rgba(216, 227, 252, 1)'
              fontWeight='300px'
              fontFamily='DM Sans'
            >
              <MemoizedInput
                placeholder='Search'
                className='filter searchInput'
                defaultValue={searchQuery}
                onChange={_onSearchInputChange}
                autoFocus
              />

            </Th>
            <Th
              height='40px'
              color='rgba(141, 151, 182, 1)'
              fontWeight='300px'
              borderRight='1px solid rgba(216, 227, 252, 1)'
              fontFamily='DM Sans'>
            </Th>

            <Th
              height='40px'
              color='rgba(141, 151, 182, 1)'
              fontWeight='300px'
              borderRight='1px solid rgba(216, 227, 252, 1)'
              fontFamily='DM Sans'>
            </Th>
          </Tr>

        </Thead>
        <Tbody>
          {_QueryVendorAccountConnected.loading ? (
            <TableSpinner isLoading={true} />
          ) : filteredData.length === 0 ? (
            <TableSpinner isLoading={false} isEmpty={true} />
          ) : (
            filteredData.map(({ displayName, Smpp = {} }, index) =>
              _.isEmpty(Smpp?.id) ? null : (
                <Tr key={String.random(8)}>
                  <Td
                    height='40px'
                    isNumeric
                    borderRight='1px solid rgba(216,227,252,1)'>
                    {' '}
                    <Text
                      color='rgba(43, 54, 116, 1)'
                      fontWeight='700'
                      textAlign='center'
                      fontSize='13px'>
                      {' '}
                      {index + 1}
                    </Text>
                  </Td>
                  <Td border='1px solid rgba(216,227,252,1)'>
                    <Flex justify='center' align='center'>
                      <Button my='0px' height='10px' _hover={{ 'bg': 'none' }} _active={{ 'bg': 'none' }} bg='none' onClick={async () => {
                        // If smpp is active then deactivate it.
                        setPlayIsLoading(i => [...i, Smpp.id])

                        // If given smpp is active then deactivate it else activate it.
                        if (Smpp.isActive) {
                          // Mutation to deactivate the smpp.
                          const MutationSmppActiveInActiveResponse = await MutationSmppActiveInActive({ 'variables': { 'smppId': Smpp.id, 'isActive': false } })

                          // Show toast.
                          toast(MutationSmppActiveInActiveResponse?.data?.SmppActiveInActive?.message ?? 'Successfully destroyed the smpp.')

                          // Remove the smpp id from the loading array.
                          return setPlayIsLoading(i => i.filter(j => j !== Smpp.id))
                        }

                        // Activate the smpp.
                        const MutationSmppActiveInActiveResponse = await MutationSmppActiveInActive({ 'variables': { 'smppId': Smpp.id, 'isActive': true } })

                        // If smpp is inactive then activate it.
                        toast(MutationSmppActiveInActiveResponse?.data?.SmppActiveInActive?.message ?? 'Successfully booted the smpp.')

                        // Remove the smpp id from the loading array.
                        return setPlayIsLoading(i => i.filter(j => j !== Smpp.id))
                      }} px='0' py='0' color='green.400'>
                        {playIsLoading.includes(Smpp.id) ? (<Spinner size='sm' />) : Smpp.isActive ? (<HiStop size={17} />) : (<HiPlay size={17} />)}
                      </Button>
                      <Button my='0px' height='10px' pl='10px' borderLeft='2px solid var(--chakra-colors-gray-300)' borderRadius={0} _hover={{ 'bg': 'none' }} _active={{ 'bg': 'none' }} bg='none' onClick={async () => {
                        // If smpp is active then deactivate it.
                        setIsCacheClearLoading(i => [...i, Smpp.id])

                        // Activate the smpp.
                        const MutationSmppActiveInActiveResponse = await MutationSmppActiveInActive({ 'variables': { 'smppId': Smpp.id, 'isActive': false, 'clearSmppBuild': true } })

                        // If smpp is inactive then activate it.
                        toast(MutationSmppActiveInActiveResponse?.data?.SmppActiveInActive?.message ?? 'Successfully cleared the smpp cache.')

                        // Remove the smpp id from the loading array.
                        return setIsCacheClearLoading(i => i.filter(j => j !== Smpp.id))
                      }} px='0' py='0' color='tomato'>
                        {isCacheClearLoading.includes(Smpp.id) ? (<Text fontSize={13} fontWeight={900}>PLEASE WAIT</Text>) : (<Text fontSize={13} fontWeight={900}>CLEAN CACHE</Text>)}
                      </Button>
                    </Flex>
                  </Td>
                  <Td textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    <Text
                      color='rgba(43, 54, 116, 1)'
                      fontWeight='700'
                      fontSize='13px'>
                      {' '}
                      {displayName}{' '}
                    </Text>
                  </Td>
                  <Td borderRight='1px solid rgba(216,227,252,1)'>
                    <Flex justify='center' align='center'>
                      {' '}
                      <Button
                        width='80.65px'
                        height='22px'
                        color={isConnect ? 'rgb(32, 32, 32)' : 'rgba(217, 11, 11, 1)'}
                        fontSize='13px'
                        background={isConnect ? 'rgba(46, 204, 113, 0.3)' : 'rgba(255, 242, 242, 1)'}>
                        {isConnect ? '.Online' : '.Offline'}
                      </Button>{' '}
                    </Flex>
                  </Td>
                  <Td>
                    {' '}
                    <Text
                      color='rgba(43, 54, 116, 1)'
                      fontWeight='700'
                      fontSize='13px'>
                      {' '}
                      <Flex justify='center' align='center'>
                        {Smpp?.mode}
                      </Flex>
                    </Text>{' '}
                  </Td>
                </Tr>
              )))
          }
        </Tbody>
      </Table>
    </TableContainer>
  )

  // Return component.
  return (
    <Box
      __css={_styles}
      justifyContent='center'
      align='center'
      display='flex'
      direction='column'
      height='100%'
      w='100%'
      p={_isCurrentViewMobile ? '12px' : '22px'}
      gap={_isCurrentViewMobile ? '12px' : '22px'}
      fontSize={['clamp(12px, 1.5vw, 15px)']}
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Flex justify='space-between' alignItems='center' width='100%'>
        <Text
          color='rgba(43,54,116,1)'
          fontWeight='700'
          fontSize={{ 'base': 'sm', 'md': 'md' }}
          textAlign='left'>
          {title ?? 'NOT CONNECTED VENDOR ACCOUNTS'}
        </Text>
      </Flex>
      <TableToRender />
    </Box>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isConnect': PropTypes.bool,
  'title': PropTypes.string
}


/*
 * EXPORTS
 */
export default Index
