/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import _ from 'underscore' // Npm: underscore library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerAccountReadQuery from './__query__/index.customerAccount.read.query'


/*
 * OBJECTS
 */
const Index = ({ onChange, customerType, accountId, ...props }) => {
  // Const assignment.
  const _skipDifference = 10000

  // Hook assignment.
  const _QueryCustomerAccountRead = useQuery(CustomerAccountReadQuery, { 'variables': { accountId, customerType, 'take': _skipDifference, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Return component.
  return (
    <MemoizedSelect
      onChange={onChange}
      value={props.value ?? props.data}
      placeholder='Select customer account'
      options={_.compact(_QueryCustomerAccountRead?.data?.CustomerAccountRead?.map(({ id, displayName, Customer }) => {
        // If customerType is present then
        // filter out those customer accounts.
        if (customerType && Customer?.type !== customerType) return void 0

        // Return the customer account.
        return displayName && id ? `${displayName} (${id})` : void 0
      }))}
      {...props}
    />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onChange': PropTypes.func.isRequired,
  'customerType': PropTypes.string.isRequired,
  'accountId': PropTypes.string.isRequired,
  'value': PropTypes.string,
  'data': PropTypes.string
}


/*
 * EXPORT
 */
export default Index
