/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore.js library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiArrowUturnDown } from 'react-icons/hi2' // Npm: react-icons library.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'
import DownloadToExcel from 'components/DownloadToExcel'
import CustomerReportDropDownOptions from 'components/CustomerReportDropDownOptions'
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerReadQuery from './__query__/index.customer.read.query'


/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 50

  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [customerType, setCustomerType] = React.useState(void 0)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [skipPage, setSkipPage] = React.useState(0)
  const _QueryCustomerRead = useQuery(CustomerReadQuery, {
    'variables': {
      'search': searchQuery,
      customerType,
      'skip': skipPage * skipDifference,
      'take': skipDifference,
      'smsStartDate': dateRange?.selection.startDate,
      'smsEndDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _tableHeaderHeightRef = React.useRef(0)
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  const sortedData = React.useMemo(() => {
    const data = _QueryCustomerRead?.data?.CustomerRead ?? [];
    return data.sort((a, b) => {

      const keys = [
        "smsCount",
        "smsDelivered",
        "smsFailed",
        "smsAccepted",
        "smsUndelivered",
      ];
      // Get the maximum value for customer A
      const maxA = Math.max(
        ...keys.map(key => a?._smsCountForGivenCustomer?.[a?.id]?.[key] || 0)
      );
      // Get the maximum value for customer B
      const maxB = Math.max(
        ...keys.map(key => b?._smsCountForGivenCustomer?.[b?.id]?.[key] || 0)
      );
      return maxB - maxA; // Descending order
    });
  }, [_QueryCustomerRead?.data?.CustomerRead]);

  // Return component.
  return (
    <>
      <Flex className='reportsCustomer base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='flex-end' alignItems='center'>
            <Flex flex={0.6} gap='12px' flexDir='column'>
              <Text as='h3' fontSize={17} fontWeight={500} display='flex' alignItems='center' color='brand.500' gap='6px'><HiArrowUturnDown />{_isCurrentViewMobile ? 'Filter Rows' : 'Filter Rows based on Date'}.</Text>
              <DateRangePicker
                startDate={dateRange?.selection?.startDate}
                endDate={dateRange?.selection?.endDate}
                w='100%'
                fontSize='13px'
                height='max-content'
                gap='6px'
                setSelectDate={setDateRange}
              />
            </Flex>
            <Flex flex={0.5} justifyContent='flex-end'>
              <DownloadToExcel
                cellsData={sortedData?.map((item, __index) =>
                  'READ_SUCCESSFUL' === item.status ? {
                    'S.No.': `${__index + (skipDifference * skipPage) + 1}.`,
                    'Company Name': item?.Company?.displayName ?? '-',
                    'Open SID': _.compact(_.flatten(_.pluck(_.flatten(_.pluck(item.CustomerAccount, 'Smpp')), 'sourceAddress')))?.join(', ') ?? '-',
                    'Total Sms': item?._smsCountForGivenCustomer?.[item?.id]?.smsCount ?? '-',
                    'Sms Delivered': item?._smsCountForGivenCustomer?.[item?.id]?.smsDelivered ?? '-',
                    'Sms Failed': item?._smsCountForGivenCustomer?.[item?.id]?.smsFailed ?? '-',
                    'Sms Pending': item?._smsCountForGivenCustomer?.[item?.id]?.smsAccepted ?? '-',
                    'Sms Undelivered': item?._smsCountForGivenCustomer?.[item?.id]?.smsUndelivered ?? '-',
                    'Dlr Percentage': `${((item?._smsCountForGivenCustomer?.[item?.id]?.smsDelivered / item?._smsCountForGivenCustomer?.[item?.id]?.smsCount) * 100)?.toFixed(2) ?? '-'}%`
                  } : void 0)}
                headersData={[
                  'S.No.',
                  'Company Name',
                  'Open SID',
                  'Total Sms',
                  'Sms Delivered',
                  'Sms Failed',
                  'Sms Pending',
                  'Sms Undelivered',
                  'Dlr Percentage'
                ].map(i => ({ 'key': i, 'label': i }))}
              />
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr
                  style={headerStyle}
                  position='sticky'
                  top={0}
                  zIndex='docked'>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No.
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Company Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Customer Type
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Total Sms
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Sms Delivered
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Sms Failed
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Sms Pending
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Sms Undelivered
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Dlr Percentage
                  </Td>
                  <Td
                    style={rowStyle}>
                    Actions
                  </Td>
                </Tr>
                <Tr key={String.random(8)}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={searchQuery}
                      onChange={_onSearchInputChange}
                      autoFocus
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedSelect
                      placeholder='Select Account Type'
                      className='filter searchInput'
                      onChange={e => setCustomerType(_.isEmpty(e?.target?.value) ? void 0 : e?.target?.value)}
                      options={_.without(Object.React.App.enums.CUSTOMER_TYPE.enums?.map(i => i?.key), 'TERMINATION')}
                      data={customerType}
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}></Td>
                </Tr>
              </Thead>
              {_QueryCustomerRead.loading ? (
                <TableSpinner
                  isLoading={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : 0 === _QueryCustomerRead?.data?.CustomerRead?.length || (0 < _QueryCustomerRead?.data?.CustomerRead?.length && _.every(_.pluck(_QueryCustomerRead?.data?.CustomerRead, 'status'), j => 'NO_SMS_FOUND' === j || 'CUSTOMERS_NOT_FOUND' === j)) ? (
                <TableSpinner
                  isLoading={false}
                  isEmpty={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryCustomerRead?.data?.CustomerRead?.map(
                    (item, __index) => (
                      <Tr key={String.random(8)}>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          {`${__index + (skipDifference * skipPage) + 1}.`}
                        </Td>
                        <Td
                          width='100%'
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.Company?.displayName ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.type ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?._smsCountForGivenCustomer?.[item?.id]?.smsCount ?? '0'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?._smsCountForGivenCustomer?.[item?.id]?.smsDelivered ?? '0'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?._smsCountForGivenCustomer?.[item?.id]?.smsFailed ?? '0'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?._smsCountForGivenCustomer?.[item?.id]?.smsAccepted ?? '0'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?._smsCountForGivenCustomer?.[item?.id]?.smsUndelivered ?? '0'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {!item?._smsCountForGivenCustomer?.[item?.id]?.smsCount ? '0' : (((item?._smsCountForGivenCustomer?.[item?.id]?.smsDelivered || 0) / (item?._smsCountForGivenCustomer?.[item?.id]?.smsCount || 0)) * 100)?.toFixed(2) ?? '0'}%
                        </Td>
                        <Td
                          style={rowStyle}>
                          <CustomerReportDropDownOptions
                            customerId={item?.id}
                            type={item?.type}
                            currency={item?.Billing?.currency}
                          />
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.first(_QueryCustomerRead?.data?.CustomerRead)?._totalCount ?? 0}
        onPageChange={setSkipPage}
      />
    </>
  )
}




/*
 * EXPORTS
 */
export default Index
