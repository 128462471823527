/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query ContentTranslationReadQuery($type: CUSTOMER_TYPE!) {
  ContentTranslationRead(type: $type) {
    id,
    createdAt,
    updatedAt,
    strategy,
    suffix,
    prefix,
    omitWords,
    regex,
    type,
    status,
    message,
    CustomerAccount {
      displayName,
      id
      },
    VendorAccount {
      displayName,
      id
    }
    Mcc {
      mcc,
      countryName
    }
  }
}
`
