/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query RouteReadQuery($search: String, $type: CUSTOMER_TYPE, $skip: Int) {
  RouteRead(search: $search, type: $type, take: 10000, skip: $skip) {
    id,
    displayName,
    type,
    message,
    status
  }
}
`
