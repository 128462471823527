/*
 * IMPORTS
 */
import 'nprogress/nprogress.css' // Npm: Progress bar.
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: React.js prop types for type checking.
import NProgress from 'nprogress' // Npm: Progress bar.
import _ from 'underscore' // Npm: Utility module.
import { Route, Switch, useHistory, useLocation } from 'react-router-dom' // Npm: React router dom library.
import { connect } from 'react-redux' // Npm: react native redux library.
import { Toaster } from 'react-hot-toast' // Npm: React hot toast.
import { ApolloProvider, useMutation, useQuery } from '@apollo/client' // Npm: Apollo client for handling graphql.
import { Box, SimpleGrid, useBreakpointValue, useDisclosure, useTheme } from '@chakra-ui/react' // Npm: A simple, modular and accessible component library for React.js.
import { SiAdblock } from 'react-icons/si' // Npm: React icons.
import { FaRoad } from 'react-icons/fa' // Npm: React icons.
import {
  HiBanknotes,
  HiChartBar,
  HiChatBubbleBottomCenterText,
  HiChatBubbleLeftEllipsis,
  HiCodeBracketSquare,
  HiCurrencyDollar,
  HiCurrencyEuro,
  HiDocumentChartBar,
  HiDocumentMagnifyingGlass,
  HiDocumentText,
  HiFlag,
  HiFolder,
  HiHome,
  HiInboxStack,
  HiNoSymbol,
  HiPhoneXMark,
  HiServer,
  HiStar,
  HiUserGroup,
  HiUserMinus,
  HiUserPlus,
  HiUsers,
  HiWrenchScrewdriver
} from 'react-icons/hi2' // Npm: React icons.


/*
 * PACKAGES
 */
import SideBarNavigation from 'components/SideBar'
import SideBarNavigationContext from 'components/SideBar/index.context'
import NavigationBar from 'components/NavBar'
import DlrPercentage from 'components/DlrPercentage'
import TotalSmsDelivered from 'components/TotalSmsDelivered'
import NewCustomer from 'components/NewCustomer'
import TotalSmsSent from 'components/TotalSmsSent'


/*
 * APOLLO
 */
import { ApolloClientProvider } from './index.apollo.client'


/*
 * STYLES
 */
import 'mapbox-gl/dist/mapbox-gl.css'


/*
 * SCREENS
 */
import Page404 from './pages/404'
import PageLogin from './pages/Login'
import PageMasterView from './pages/MasterView'
import PageAccount from './pages/Account'
import PageDeveloper from './pages/Developer'
import PageAccountPerformance from './pages/Account/Performance'
import PageCustomer from './pages/Customer'
import PageCustomerAccount from './pages/Customer/Account'
import PageCustomerPayment from './pages/Customer/Payment'
import PageVendor from './pages/Vendor'
import PageVendorAccount from './pages/Vendor/Account'
import PageVendorPayment from './pages/Vendor/Payment'
import PageVendorReport from './pages/Vendor/Report'
import PageRoute from './pages/Route'
import PageReportsCustomerMargin from './pages/Reports/Customer/Margin'
import PageRoutePlan from './pages/Route/Plan'
import PageRate from './pages/Rate'
import PageRatePlan from './pages/Rate/Plan'
import PageReportsCredit from './pages/Reports/Credit'
import PageReportsCreditHistory from './pages/Reports/Credit/History'
import PageReportsCustomer from './pages/Reports/Customer'
import PageReportsMessage from './pages/Reports/Message'
import PageReportsVendor from './pages/Reports/Vendor'
import PageReportsVendorMargin from './pages/Reports/Vendor/Margin'
import PageNetworkMcc from './pages/Network/Mcc'
import PageNetworkMccMnc from './pages/Network/Mcc/Mnc'
import PageNetworkMccMncOperatorPrefix from './pages/Network/Mcc/Mnc/OperatorPrefix'
import ContentTranslation from './pages/ContentTranslation'
import PageBlockNumber from './pages/Block/Number'
import PageBlockIp from './pages/Block/Ip'
import TranslationKeywordDirectory from './pages/Directory/KeywordDirectory'
import TranslationKeywordDirectoryKeyword from './pages/Directory/KeywordDirectory/Keyword'
import SenderIdsDirectory from './pages/Directory/SenderIdsDirectory'
import SenderIdsDirectorySenderIds from './pages/Directory/SenderIdsDirectory/SenderIds'
import PageVendorAccounttDirectory from './pages/Directory/VendorAccounttDirectory'
import PageVendorAccounttDirectoryVendorAccountt from './pages/Directory/VendorAccounttDirectory/VendorAccountt'
import IpDirectory from './pages/Directory/IpDirectory'
import IpDirectoryIp from './pages/Directory/IpDirectory/Ip'
import PagePhoneNumberoDirectory from './pages/Directory/PhoneNumberoDirectory'
import PagePhoneNumberoDirectoryPhoneNumbero from './pages/Directory/PhoneNumberoDirectory/PhoneNumbero'
import Lcr from './pages/Lcr'
import PageTool from './pages/Tool'
import PageFinanceInvoice from './pages/Finance/Invoice'
import PageFinanceEntity from './pages/Finance/Entity'
import PageFinanceSoa from './pages/Finance/Soa'
import PageVendorInvoice from './pages/Vendor/Invoice'
import PageLogsCustomer from './pages/Logs/Customer'
import PageLogsVendor from './pages/Logs/Vendor'


/*
 * GRAPHS
 */
import AccountLogoutMutation from './__mutation__/index.account.logout.mutation'
import AccountReadQuery from './__query__/index.account.read.query'


/*
 * OBJECTS
 */
const Index = ({ account, AccountUpdate, AccountLogout, ClearEverything, ...props }) => {
  // Const assignment.
  const _routes = [
    {
      'name': 'Master View',
      'path': '/',
      'icon': <HiHome size={21} />,
      'component': PageMasterView
    },
    {
      'name': 'Login',
      'path': '/login',
      'icon': <></>,
      'collapse': false,
      'hide': true,
      'noLayout': true,
      'component': PageLogin
    },
    {
      'name': 'Account Management',
      'path': '/account',
      'icon': <HiUserPlus size={21} />,
      'component': PageAccount,
      'items': [
        {
          'name': 'Account Performance',
          'path': '/account/performance',
          'icon': <HiUserMinus size={17} />,
          'component': PageAccountPerformance,
          'hide': true
        }
      ]
    },
    {
      'name': 'Customer Management',
      'path': '/customer',
      'icon': <HiUserGroup size={21} />,
      'component': PageCustomer,
      'items': [
        {
          'name': 'Customer Smpp',
          'path': '/customer/smpp',
          'icon': <HiServer size={17} />,
          'component': PageCustomerAccount
        },
        {
          'name': 'Customer Payment',
          'path': '/customer/payment',
          'icon': <HiServer size={17} />,
          'component': PageCustomerPayment
        }
      ]
    },
    {
      'name': 'Vendor Management',
      'path': '/vendor',
      'icon': <HiServer size={21} />,
      'component': PageVendor,
      'items': [
        {
          'name': 'Vendor Smpp',
          'path': '/vendor/smpp',
          'icon': <HiServer size={17} />,
          'component': PageVendorAccount
        },
        {
          'name': 'Vendor Payment',
          'path': '/vendor/payment',
          'icon': <HiServer size={17} />,
          'component': PageVendorPayment
        },
        {
          'name': 'Vendor Invoice',
          'path': '/vendor/invoice',
          'icon': <HiServer size={17} />,
          'component': PageVendorInvoice
        },
        {
          'name': 'Vendor Report',
          'path': '/vendor/report',
          'icon': <HiWrenchScrewdriver size={20} />,
          'hide': true,
          'component': PageVendorReport
        }
      ]
    },
    {
      'name': 'LCR',
      'path': '/lcr/rates',
      'icon': <HiDocumentMagnifyingGlass size={21} />,
      'component': Lcr,
      'items': [
        {
          'name': 'LCR',
          'path': '/lcr',
          'icon': <HiDocumentMagnifyingGlass size={21} />,
          'hide': true,
          'component': Lcr
        }
      ]
    },
    {
      'name': 'Finance',
      'path': '/finance',
      'icon': <HiBanknotes size={21} />,
      'collapse': true,
      'items': [
        {
          'name': 'Manage Invoice',
          'path': '/finance/invoice',
          'component': PageFinanceInvoice
        },
        {
          'name': 'Statement of Accounts',
          'path': '/finance/statement',
          'component': PageFinanceSoa
        },
        {
          'name': 'Manage Entity',
          'path': '/invoice/entity',
          'component': PageFinanceEntity
        }
      ]
    },
    {
      'name': 'Logs',
      'path': '/logs',
      'icon': <HiInboxStack size={21} />,
      'collapse': true,
      'items': [
        {
          'name': 'Customer',
          'path': '/logs/customer',
          'component': PageLogsCustomer
        },
        {
          'name': 'Vendor',
          'path': '/logs/vendor',
          'component': PageLogsVendor
        }
      ]
    },
    {
      'name': 'Route Management',
      'path': '/route',
      'icon': <FaRoad size={21} />,
      'component': PageRoute,
      'items': [
        {
          'name': 'Route Plan',
          'path': '/route/plan',
          'component': PageRoutePlan
        }
      ]
    },
    {
      'name': 'Rate Management',
      'path': '/rate',
      'icon': <HiCurrencyDollar size={21} />,
      'component': PageRate,
      'items': [
        {
          'name': 'Rate Plan',
          'path': '/rate/plan',
          'component': PageRatePlan
        }
      ]
    },
    {
      'name': 'Reports',
      'path': '/reports',
      'icon': <HiDocumentText size={21} />,
      'collapse': true,
      'items': [
        {
          'name': 'Credit Report',
          'path': '/reports/credit',
          'icon': <HiCurrencyEuro size={21} />,
          'component': PageReportsCredit,
          'items': [
            {
              'name': 'Credit History',
              'path': '/reports/credit/history',
              'icon': <HiDocumentText size={21} />,
              'component': PageReportsCreditHistory
            }
          ]
        },
        {
          'name': 'Customer Report',
          'path': '/reports/customer',
          'icon': <HiUsers size={21} />,
          'component': PageReportsCustomer,
          'items': [
            {
              'name': 'Customer Margin',
              'path': '/reports/customer/margin',
              'hide': true,
              'component': PageReportsCustomerMargin
            }
          ]
        },
        {
          'name': 'Message Report',
          'path': '/reports/message',
          'icon': <HiChatBubbleLeftEllipsis size={21} />,
          'component': PageReportsMessage
        },
        {
          'name': 'Vendor Report',
          'path': '/reports/vendor',
          'icon': <HiDocumentChartBar size={21} />,
          'component': PageReportsVendor,
          'items': [
            {
              'name': 'Vendor Margin',
              'path': '/reports/vendor/margin',
              'hide': true,
              'component': PageReportsVendorMargin
            }
          ]
        }
      ]
    },
    {
      'name': 'Network Manager',
      'path': '/network',
      'icon': <HiChartBar size={21} />,
      'collapse': false,
      'component': PageNetworkMcc,
      'items': [
        {
          'name': 'Mcc Manager',
          'path': '/network/mcc',
          'icon': <HiFlag size={21} />,
          'hide': true,
          'component': PageNetworkMcc
        },
        {
          'name': 'Mnc Manager',
          'path': '/network/mcc/mnc',
          'icon': <HiChartBar size={21} />,
          'hide': true,
          'component': PageNetworkMccMnc
        },
        {
          'name': 'Mnc Manager',
          'path': '/network/mcc/mnc/prefix',
          'icon': <HiChartBar size={21} />,
          'hide': true,
          'component': PageNetworkMccMncOperatorPrefix
        }
      ]
    },
    {
      'name': 'Blocking System',
      'path': '/block',
      'icon': <SiAdblock size={21} />,
      'component': PageReportsVendor,
      'collapse': true,
      'items': [
        {
          'name': 'Block Number\'s',
          'path': '/block/phone',
          'icon': <HiPhoneXMark size={17} />,
          'component': PageBlockNumber
        },
        {
          'name': 'Block IP\'s',
          'path': '/block/ips',
          'icon': <HiUserMinus size={19} />,
          'component': PageBlockIp
        }
      ]
    },
    {
      'name': 'Content Translation',
      'path': '/content/translation',
      'icon': <HiChatBubbleBottomCenterText size={21} />,
      'component': ContentTranslation
    },
    {
      'name': 'Developer Account',
      'path': '/developer',
      'icon': <HiCodeBracketSquare size={21} />,
      'component': PageDeveloper
    },
    {
      'name': 'Folder',
      'path': '/directory',
      'icon': <HiFolder size={21} />,
      'collapse': true,
      'items': [
        {
          'name': 'Keywords',
          'path': '/keywords',
          'hide': false,
          'component': TranslationKeywordDirectory,
          'items': [
            {
              'name': 'Keywords',
              'path': '/keywords/keyword',
              'icon': <HiNoSymbol size={15} />,
              'hide': false,
              'component': TranslationKeywordDirectoryKeyword
            }
          ]
        },
        {
          'name': 'Sender Id\'s',
          'path': '/senderIds/',
          'hide': false,
          'component': SenderIdsDirectory,
          'items': [
            {
              'name': 'Sender Id\'s',
              'path': '/senderIds/senderId',
              'icon': <HiNoSymbol size={15} />,
              'hide': false,
              'component': SenderIdsDirectorySenderIds
            }
          ]
        },
        {
          'name': 'Ip\'s',
          'path': '/ip/',
          'hide': false,
          'component': IpDirectory,
          'items': [
            {
              'name': 'Sender Id\'s',
              'path': '/ip/ip',
              'icon': <HiNoSymbol size={15} />,
              'hide': false,
              'component': IpDirectoryIp
            }
          ]
        },
        {
          'name': 'Phone Number\'s',
          'path': '/phonenumber/',
          'hide': false,
          'component': PagePhoneNumberoDirectory,
          'items': [
            {
              'name': 'Phone Number\'s',
              'path': '/phonenumber/phonenumber',
              'icon': <HiNoSymbol size={15} />,
              'hide': false,
              'component': PagePhoneNumberoDirectoryPhoneNumbero
            }
          ]
        },
        {
          'name': 'LCR V2',
          'path': '/vendoraccountt/',
          'hide': false,
          'component': PageVendorAccounttDirectory,
          'items': [
            {
              'name': 'LCR V2',
              'path': '/vendoraccountt/vendoraccountt',
              'icon': <HiNoSymbol size={15} />,
              'hide': false,
              'component': PageVendorAccounttDirectoryVendorAccountt
            }
          ]
        }
      ]
    },
    {
      'name': 'System Tools',
      'path': '/tool',
      'icon': <HiWrenchScrewdriver size={20} />,
      'component': PageTool
    }
  ]

  // Object assignment.
  const _FlattenItems = __items => __items.flatMap(item => Object.prototype.hasOwnProperty.call(item, 'items') ? [item.items, ..._FlattenItems(item.items)] : [])

  // Apollo client instance.
  const _ApolloClientInstance_ = React.useRef(ApolloClientProvider(account, { ClearEverything }))

  // Hook assignment.
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const [navHeight, setNavHeight] = React.useState(99)
  const [MutationAccountLogout] = useMutation(AccountLogoutMutation, { 'context': { 'headers': { 'l-authorization': account.token } }, 'client': _ApolloClientInstance_.current })
  const _QueryAccountRead = useQuery(AccountReadQuery, {
    'context': {
      'headers': {
        'l-authorization': account.token
      }
    },
    'client': _ApolloClientInstance_.current,
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _locationRef = useLocation()
  const _historyRef = useHistory()
  const _themeRef = useTheme()
  const { onOpen } = useDisclosure()
  const _navBarHeightRef = React.useRef({ 'clientHeight': '99' })
  const _routeRef = React.useRef([..._routes, ..._.flatten(_FlattenItems(_routes))])
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Object assignment.
  const _Logout = () => MutationAccountLogout().then(() => AccountLogout()).catch(error => { throw error })
  const _GetActiveRoute = routes => {
    // Local variable.
    let i, n

    // Object assignment.
    const _ActiveRoute = o => _locationRef.pathname.split('/')[2] === o?.split('/')?.[2]
    const _NonCollapsibleActiveRoute = o => _locationRef.pathname === o

    // Loop through routes.
    for (i = 0; i < routes?.length; i++) {
      // If route is collapsible.
      if (routes[i]?.collapse) {
        // Loop through items.
        for (n = 0; n < routes[i]?.items.length; n++) if (_ActiveRoute(routes[i]?.items[n]?.path)) return `${routes[i]?.items[n]?.name}`
      } else if (!routes[i]?.collapse) {
        // If route is not collapsible.
        if (_NonCollapsibleActiveRoute(routes[i]?.path)) return `${routes?.[i]?.items?.[n]?.name ?? routes[i]?.name}`
      }
    }

    // Return active route.
    return _ActiveRoute
  }
  const _GetActiveNavBar = routes => {
    // Local variable.
    let i

    // Const assignment.
    const _activeNavBar = false

    // Loop through routes.
    for (i = 0; i < routes.length; i++) {
      // If route is collapsible.
      if (routes[i].collapse) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBar(routes[i].items)

        // If route is collapsible.
        if (_collapseActiveNavbar !== _activeNavBar) return _collapseActiveNavbar
      } else if (routes[i].category) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBar(routes[i].items)

        // If route is category.
        if (_collapseActiveNavbar !== _activeNavBar) return _collapseActiveNavbar
      } else if (-1 !== window.location.href.indexOf(routes[i].layout + routes[i].path)) {
        // If route is not collapsible.
        return routes[i].secondary
      }
    }

    // Return active navbar.
    return _activeNavBar
  }
  const _GetActiveNavBarText = routes => {
    // Local variable.
    let i

    // Const assignment.
    const _activeNavBar = false

    // Loop through routes.
    for (i = 0; i < routes.length; i++) {
      // If route is collapsible.
      if (routes[i].collapse) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBarText(routes[i].items)

        // If route is collapsible.
        if (_collapseActiveNavbar !== _activeNavBar) return _collapseActiveNavbar
      } else if (routes[i].category) {
        // Const assignment.
        const _categoryActiveNavBar = _GetActiveNavBarText(routes[i].items)

        // If route is category.
        if (_categoryActiveNavBar !== _activeNavBar) return _categoryActiveNavBar
      } else if (-1 !== window.location.href.indexOf(routes[i].layout + routes[i].path)) {
        // If route is not collapsible.
        return routes[i].messageNavbar
      }
    }

    // Return active navbar.
    return _activeNavBar
  }

  // Event listener.
  React.useEffect(() => {
    // Update navHeight when ever it is available.
    setNavHeight(_navBarHeightRef?.current?.clientHeight)
  }, [_navBarHeightRef])
  React.useEffect(() => {
    // Const assignment.
    const _accountReadQuery = _QueryAccountRead.data?.AccountRead

    /*
     * Only update account details if they
     * are available.
     */
    if ('READ_SUCCESSFUL' === _.first([_accountReadQuery ?? {}]).status && !_.isEmpty(_accountReadQuery) && !_accountReadQuery.ban) {
      // Const assignment.
      const _dataToUpdate = {}

      // Update individual data to the context.
      if (!_.isEmpty(_accountReadQuery.email)) Object.assign(_dataToUpdate, { 'email': _accountReadQuery.email })
      if (!_.isEmpty(_accountReadQuery?.displayName)) Object.assign(_dataToUpdate, { 'displayName': _accountReadQuery?.displayName })
      if (!_.isEmpty(_accountReadQuery?.bio)) Object.assign(_dataToUpdate, { 'bio': _accountReadQuery?.bio })
      if (!_.isEmpty(_accountReadQuery?.thumbnailStoredAt)) Object.assign(_dataToUpdate, { 'thumbnailStoredAt': _accountReadQuery?.thumbnailStoredAt.path })
      if (!_.isEmpty(_accountReadQuery?.profileCoverThumbnailStoredAt)) Object.assign(_dataToUpdate, { 'profileCoverThumbnailStoredAt': _accountReadQuery?.profileCoverThumbnailStoredAt.path })

      // Update store with new information.
      AccountUpdate(_dataToUpdate)
    } else if ((!_.isEmpty(_accountReadQuery) && _accountReadQuery.ban)) {
      /*
       * Logout immediately and remove
       * all credentials.
       */
      _Logout().catch(e => {
        // Report failure.
        throw e
      })
    }
  }, [_QueryAccountRead.data])
  React.useEffect(() => {
    // Update apollo client instance.
    _ApolloClientInstance_.current = ApolloClientProvider(account, { ClearEverything })

    // If user is not logged in then redirect to login page.
    if ('/login' !== _locationRef.pathname && account && !account.isUserLoggedIn) _historyRef.push('/login')
  }, [account])
  React.useEffect(() => {
    // Start NProgress when the document is ready
    NProgress.configure({ 'showSpinner': false })
    NProgress.start()

    // Clear timeout after sometime.
    const j = setTimeout(() => {
      // Stop NProgress after sometime
      NProgress.done()

      // Clear timeout.
      clearTimeout(j)

      // Update navHeight when ever it is available.
      setNavHeight(_navBarHeightRef?.current?.clientHeight)
    }, 1000)
  })

  // Const assignment.
  const _letUIOverflowFollowingPages = [_isCurrentViewMobile ? '/finance/invoice' : void 0, '/']

  // Return component.
  return (
    <SideBarNavigationContext.Provider value={[isDrawerOpen, setIsDrawerOpen]}>
      <ApolloProvider client={_ApolloClientInstance_.current}>
        <Switch>
          {_routeRef.current.map(i => (
            <Route
              exact
              path={i.path}
              render={__props => {
                // Const assignment.
                const Component = i.component

                // Return component.
                return i.noLayout ? <Component {...__props} /> : (
                  <>
                    <SideBarNavigation routes={_routes} display='none' {...props} />
                    <Box
                      float='right'
                      bg='#F5F8FD'
                      display='flex'
                      flexDirection='column'
                      position='relative'
                      minH='inherit'
                      overflowY={_letUIOverflowFollowingPages?.includes(i.path) ? void 0 : 'scroll'}
                      w={{ 'base': '100%', 'xl': '80%' }}
                      height='inherit'
                      maxWidth={{ 'base': '100%', 'xl': '80%' }}
                      transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                      transitionDuration='.2s, .2s, .35s'
                      transitionProperty='top, bottom, width'
                      transitionTimingFunction='linear, linear, ease'
                      overflowX='hidden'>
                      <Box
                        ref={_navBarHeightRef}
                        py='16px'
                        px='22px'
                        zIndex={100}
                        w={{
                          'base': '100%',
                          'md': `${'/' === i.path ? 'calc(100% / 1.0)' : '100%'}`,
                          'lg': `${'/' === i.path ? 'calc(100% / 1.0)' : '100%'}`,
                          'xl': `${'/' === i.path ? 'calc(100% / 1.25)' : '100%'}`
                        }}
                        position={{
                          'base': `${'/' === i.path ? 'absolute' : 'relative'}`,
                          'md': `${'/' === i.path ? 'fixed' : 'relative'}`
                        }} pb='0'>
                        <NavigationBar
                          onOpen={onOpen}
                          logoText={''}
                          timelineChildren={(
                            <SimpleGrid
                              position='relative'
                              top={{ 'base': '22px', 'sm': '12px' }}
                              columns={{ 'sm': 1, 'md': 2, 'lg': 2, 'xl': 4, '2xl': 4 }}
                              gap='22px'>
                              <TotalSmsSent />
                              <NewCustomer />
                              <TotalSmsDelivered />
                              <DlrPercentage />
                            </SimpleGrid>
                          )}
                          brandText={_GetActiveRoute(_routeRef.current)}
                          secondary={_GetActiveNavBar(_routeRef.current)}
                          message={_GetActiveNavBarText(_routeRef.current)}
                          fixed={true}
                          {...props}
                        />
                      </Box>
                      <Box
                        flex={1}
                        p={{ 'base': '20px', 'md': '30px' }}
                        pe='20px'
                        bg='#F5F8FD'
                        flexDirection='column'
                        height={{ 'base': _letUIOverflowFollowingPages?.includes(i.path) ? 'auto' : `calc(100% - ${navHeight}px)` }}
                        maxHeight={_letUIOverflowFollowingPages?.includes(i.path) ? 'auto' : `calc(100% - ${navHeight}px)`}
                        boxSizing='border-box'
                        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.1)'
                        display='flex'>
                        <Component {...__props} />
                      </Box>
                      <Toaster
                        position='bottom-left'
                        reverseOrder={false}
                        containerClassName='toaster'
                        toastOptions={{
                          'duration': 3000,
                          'style': {
                            'background': _themeRef.colors.purple[700],
                            'color': _themeRef.colors.white
                          }
                        }}
                      />
                    </Box>
                  </>
                )
              }}
              key={String.random(10)}
            />
          ))}
          <Route path='*' component={Page404} />
        </Switch>
      </ApolloProvider>
    </SideBarNavigationContext.Provider>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'account': PropTypes.object.isRequired,
  'AccountUpdate': PropTypes.func.isRequired,
  'AccountLogout': PropTypes.func.isRequired,
  'ClearEverything': PropTypes.func.isRequired
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account, 'platform': __state.Platform })
const _MapDispatchToProps = __dispatch => ({
  'AccountUpdate': u => __dispatch({ 'type': 'ACCOUNT_UPDATE', 'Account': u }),
  'AccountLogout': () => __dispatch({ 'type': 'ACCOUNT_CLEAR' }),
  'ClearEverything': () => {
    // Clear all data.
    __dispatch({ 'type': 'ACCOUNT_CLEAR' })
    __dispatch({ 'type': 'PASS_ON_CLEAR' })
  }
})


/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)
